.mainHeading h1 {
  text-align: center;
  font-size: 25px;
  text-transform: capitalize;
  font-family: 'Rockybilly';
  letter-spacing: 6px;
}

.mainContainer {
  color: white;
  padding-top: 10%;
  height: auto;
}

.topImageContainer {
  display: flex;
  width: 60%;
  margin: auto;
  height: auto;
}

.emotePicture {
  width: 45%;
  margin: auto;
  margin-top: 10%;
}

.lipstickImage {
  width: 45%;
  margin: auto;
}

.buttonContainer {
  width: 10%;
  margin: auto;
  margin-top: 7%;
}

.buttonContainer button {
  width: 100%;
  background-color: #007bff;
  /* Blue color, you can change this to your desired color */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-bottom: 20px;

}

.buttonContainer button:hover {
  background-color: #0056b3;
  /* Darker blue on hover, adjust as needed */
  transform: scale(1.05);
  /* Scale up a bit on hover */
}

.emotePicture:last-child {
  width: 45%;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 10%;
}

/* Try for doing the things */
/* Index.module.css */

.mainContainer {
  text-align: center;
  padding: 30px;
  margin-top: 5%;
}

.categoryButtons {
  margin-bottom: 20px;
}

.categoryButtons button {
  background-color: white;
  color: black;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  border-radius: 12px;
  font-size: 18px;
  transition: all 0.3s linear;
  font-family: 'Pacifico', cursive;
}

.categoryButtons button:hover {
  background-color: black;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  border-radius: 2px;
  font-size: 16px;
}

.imagesContainer {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  justify-content: center;

}

.imageContainer {
  width: 40%;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease-in-out;

}

.imageContainer:hover {
  transform: scale(1.1);
}

.image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  cursor: pointer;
}

.video {
  width: 100%;
  /* Set the video width to 100% of its container */
  max-height: 100%;
  /* Set the video height to auto to maintain aspect ratio */
  border: 1px solid #ccc;
  /* Add a border around the video */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); Add a shadow to the video */
}

/* Define styles for video controls */
.video::-webkit-media-controls {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

/* Define styles for video control buttons */
.video::-webkit-media-controls-play-button,
.video::-webkit-media-controls-pause-button {
  font-size: 20px;
  /* Change the size of play/pause buttons */
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  /* Add a transition for smooth opacity changes */
}

/* Style the play icon (you can customize this) */
.playIcon {
  fill: white;
  width: 36px;
  height: 36px;
}

/* Hide the play button when the video is playing */

/* Customize video controls background and icon color */
.video::-webkit-media-controls {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

/* Customize video control button size (optional) */
.video::-webkit-media-controls-play-button,
.video::-webkit-media-controls-pause-button {
  font-size: 20px;
}

.pauseIcon {
  fill: white;
  /* Change the fill color to your desired color */
  width: 36px;
  /* Adjust the width and height as needed */
  height: 36px;
  display: none;
  /* Initially hide the pause icon */
}

/* Show the pause icon when the video is playing */


/* Hide the pause icon when the video is paused or ended */
.video.paused+.playButton .pauseIcon,
.video.ended+.playButton .pauseIcon {
  display: none;
}




/* Responsiveness */
@media (min-width: 1008px) and (max-width: 1300px) {}

@media (min-width: 641px) and (max-width: 1007px) {
  .buttonContainer {
    width: 15%;
  }

}

@media (min-width: 360px) and (max-width: 640px) {
  .mainHeading h1 {
    font-size: x-large;
    font-weight: 400;
  }

  .buttonContainer {
    width: 25%;
  }

  .topImageContainer {
    width: 80%;
  }
}