.mainContainer {
    width: 100%;
    margin-top: 0%;
    color: white;
    letter-spacing: 1px;
}

.imageContainer {
    width: 80%;
    margin: 5% auto;
}

.animePicture {
    width: 100%;
    margin: auto;
}

.imageContainer h1 {
    color: white;
    font-weight: 500;
    text-align: center;
    font-size: 45px;
}

.textContainer {
    width: 50%;
    margin: auto;
    font-size: larger;
    text-align: center;
}

.textContainer h1 {
    font-size: 20px;
    text-transform: capitalize;
    font-family: 'Rockybilly';
}

.chooseMeContainer {
    width: 50%;
    display: flex;
    margin: auto;
    margin-top: 10%;
    align-items: center;
}

.chooseMeContainer h1 {
    font-size: 20px;
    text-transform: capitalize;
    font-family: 'Rockybilly';
    font-weight: 500;
}

.leftHandContainer {
    width: 50%;
}

.rightHandContainer {
    width: 50%;
}

.rightHandContainer p {
    font-weight: 300;
}





/* Responsiveness */

@media (min-width: 1008px) and (max-width: 1300px) {
    .leftHandContainer {
        width: 55%;
    }

    .rightHandContainer {
        width: 45%;
    }
}

@media (min-width: 641px) and (max-width: 1007px) {
    .imageContainer h1 {
        font-size: xx-large;
    }

    .textContainer p {
        font-size: large;
    }

    .leftHandContainer h1 {
        font-size: xx-large;
    }

    .leftHandContainer {
        width: 45%;
    }

    .rightHandContainer {
        width: 55%;
    }

    .rightHandContainer p {
        font-size: large;
    }

    .chooseMeContainer {
        width: 80%;
        text-align: center;
        flex-direction: column;
    }
}

@media (min-width: 360px) and (max-width: 640px) {
    .imageContainer h1 {
        font-size: x-large;
    }

    .textContainer {
        width: 80%;
    }

    .textContainer p {
        font-size: small;
        margin-top: 5%;
        font-weight: 300;
        text-align: center;
    }

    .leftHandContainer h1 {
        font-size: x-large;
    }

    .leftHandContainer {
        width: 40%;
    }

    .rightHandContainer {
        width: 60%;
    }

    .rightHandContainer p {
        font-size: small;
    }

    .chooseMeContainer {
        width: 80%;
        text-align: center;
        flex-direction: column;
    }
}