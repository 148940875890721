a {
    color: white;
}

.mainContainer {
    width: 100%;
    display: flex;
    align-items: center;
    color: white;
    margin-top: 2%;
}

.logoContainer {
    width: 85%;
    margin: auto;

}

.logoContainer h1 {
    font-weight: 500;
    font-size: 20px;
    text-transform: capitalize;
    font-family: 'Rockybilly';
}

.optionsContainer {
    width: 10%;
    justify-content: space-between;
}

.twitterIcon,
.discordIcon {
    font-size: xx-large;
    cursor: pointer;
}

.twitterIcon {
    margin-right: 10%;
}

/* Responsiveness */
@media (min-width: 1008px) and (max-width: 1300px) {}

@media (min-width: 641px) and (max-width: 1007px) {
    .optionsContainer {
        width: 12%;
    }
}

@media (min-width: 360px) and (max-width: 640px) {
    .optionsContainer {
        width: 17%;
    }

    .logoContainer {
        width: 75%;
    }

    .logoContainer h1 {
        font-size: 25px;
    }

    .twitterIcon,
    .discordIcon {
        font-size: x-large;
    }
}