@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

@font-face {
  font-family: 'Rockybilly';
  src: url('./font/Rockybilly.woff2') format('woff2');
}

body {
  background: linear-gradient(#E45097, #7246A4);
}

body,
h1,
div,
p,
h2,
h3,
h4 {
  margin: 0;
  font-family: 'Bebas Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}